.fixedFirstColumn td:nth-child(1), .fixedFirstColumn th:nth-child(1) {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
  white-space: nowrap;
  min-width: fit-content;
}

.fixedSecondColumn td:nth-child(2), .fixedSecondColumn th:nth-child(2) {
  position: sticky;
  background: white;
  z-index: 2;
  white-space: nowrap;
  min-width: fit-content;
}

.fiscal-year-boundary {
  border-top: 2px solid #E2E8F0;
}